import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import { FormType } from "../../types"
import formatCurrency from "../../../utils/format-currency"
import queryBannerImage from "../../hooks/query-banner-image"
import UhsmRates from "../../data/uhsm-rates.json"
import Icon from "../icons"
import ModalConfirm from "../sections/components/modal-confirm"

type FormProps = {
  form: FormType
  [key: string]: any
}

type FormData = {
  familySize: string
  maxAge: string
  plan: string
}

type CardProps = {
  plan: any
  familySize: string
  maxAge: string
  isSelected: boolean
  onSelect: (planName: string) => void
  onView: (planName: string) => void
}

type CardsProps = {
  familySize: string
  maxAge: string
  selectedPlan: string
  onSelect: (planName: string) => void
  onView: (planName: string) => void
}

const PlanCard = ({ plan, familySize, maxAge, isSelected, onSelect, onView }: CardProps) => {
  const logoImage = queryBannerImage("uhsm-logo.png")
  const logoHeight = 20
  const selectedRate = getSelectedRate(plan.name, familySize, maxAge)
  const borderColor = isSelected ? `border-success` : ``
  const logoImageAspectRatio = logoImage ? logoImage?.node?.gatsbyImageData.width / logoImage?.node?.gatsbyImageData.height : 1

  return (
    <div className={`flex flex-col p-8 w-full shadow-lg bg-white border-solid border-2 rounded ${borderColor}`}>
      <div className="flex-none w-100">
        <GatsbyImage
          image={getImage(logoImage?.node)}
          style={{
            height: `${logoHeight}px`,
            width: `${logoHeight * logoImageAspectRatio}px`,
          }}
          alt="UHSM"
        />
      </div>
      <div className="flex-grow w-100 py-4">
        <h3 className="text-lg font-bold">{plan.name}</h3>
        <h2 className="text-2xl font-bold">{formatCurrency(selectedRate?.rate ?? 0)}</h2>
      </div>

      <div className="flex-none w-100 py-2 ">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-1">
          {isSelected ? (
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-success">
              <Icon icon="checkmark" size={20} color="white" />
            </div>
          ) : (
            <button className="p-3 w-full rounded-lg bg-buttonBackground text-buttonColor" type="button" onClick={() => onSelect(plan.name)}>
              SELECT
            </button>
          )}

          <button className="w-full underline text-buttonBackground" type="button" onClick={() => onView(plan.name)}>
            COMPARE COVERAGE
          </button>
        </div>
      </div>
    </div>
  )
}

const PlanCards = ({ familySize, maxAge, selectedPlan, onSelect, onView }: CardsProps) => {
  const plans = UhsmRates.filter((uhsmPlan: any) => {
    const selectedRate = getSelectedRate(uhsmPlan.name, familySize, maxAge)
    return !!selectedRate
  })

  return (
    <div className="sm:col-span-2">
      <label className="block">Select Plan</label>
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        {plans.map((plan: any, planIndex: number) => (
          <PlanCard key={`plan-${planIndex}`} plan={plan} familySize={familySize} maxAge={maxAge} isSelected={selectedPlan === plan.name} onSelect={onSelect} onView={onView} />
        ))}
      </div>
    </div>
  )
}

const NoPlansSelected = () => (
  <div className="sm:col-span-2 max-w-4xl mx-auto text-center text-4xl p-20">
    Choose family size and
    <br />
    max age to continue.
  </div>
)

const getPlan = (planName: string): any | undefined => {
  return UhsmRates.find((uhsmPlan: any) => uhsmPlan.name === planName)
}

const getSelectedRate = (planName: string, familySize: string, maxAge: string): any | undefined => {
  if (!planName || !familySize || !maxAge) {
    return undefined
  }

  const selectedPlan = getPlan(planName)

  return selectedPlan?.rates.find((rate: any) => rate.maxAge === parseInt(maxAge))
}

const QuoteTool = ({ form, ...props }: FormProps) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm()
  const [stateData, setData] = useState({
    isSubmitting: false,
    familySize: "",
    maxAge: "",
    planName: "",
    currentPremium: "",
    comparePlanName: "",
  })

  const onSubmit = (data: FormData) => {
    if (typeof window !== "undefined" && typeof window.datadogLogs !== "undefined") {
      window.datadogLogs.logger.info("quote tool submit ", data)
    }

    location.assign(`${form.destination}?familySize=${stateData.familySize}&maxAge=${stateData.maxAge}&plan=${stateData.planName}`)
  }

  const onPlanSelected = (planName: string) => {
    setValue("planName", planName)
    setData({ ...stateData, planName })
  }

  const onPlanView = (planName: string) => {
    setData({ ...stateData, comparePlanName: planName })
  }

  const onPlanHide = () => {
    setData({ ...stateData, comparePlanName: "" })
  }

  const onCurrentPremiumChanged = (e: any) => {
    setData({ ...stateData, currentPremium: e.target.value })
  }

  const familySizeClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.familySize ? "border-red-500" : ""}`
  const maxAgeClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.maxAge ? "border-red-500" : ""}`
  const buttonClasses = `p-3 block w-full rounded-lg bg-white text-primary ${!stateData.familySize || !stateData.maxAge ? " disabled" : ""}`
  const currentPremiumClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.currentPremium ? "border-red-500" : ""}`

  const comparePlan = stateData.comparePlanName ? getPlan(stateData.comparePlanName) : undefined
  const selectedRate = getSelectedRate(stateData.planName, stateData.familySize, stateData.maxAge)
  const currentPremium = stateData.currentPremium ? parseInt(stateData.currentPremium) : 0
  const monthlySavings = selectedRate && currentPremium ? currentPremium - selectedRate.rate : undefined
  const yearlySavings = monthlySavings ? monthlySavings * 12 : undefined

  React.useEffect(() => {
    register("planName", { required: true })
  }, [register, setValue, stateData])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-3 md:p-5 lg:p-10 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 border-0 rounded-t-lg">
        <div>
          <label htmlFor="family-size" className="block">
            Family Size
          </label>
          <div className="mt-1">
            <select
              {...register("family-size", { required: true })}
              className={familySizeClasses}
              value={stateData.familySize}
              onChange={(e: { target: { value: any } }) => {
                setData({ ...stateData, familySize: e.target.value, planName: "" })
              }}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="individual">Individual</option>
              <option value="couple">Individual + 1</option>
              <option value="family">Family</option>
            </select>
          </div>
        </div>

        <div>
          <label htmlFor="max-age" className="block">
            Max Age
          </label>
          <div className="mt-1">
            <select
              {...register("max-age", { required: true })}
              className={maxAgeClasses}
              value={stateData.maxAge}
              onChange={(e: { target: { value: any } }) => {
                setData({ ...stateData, maxAge: e.target.value, planName: "" })
              }}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="17">&lt; 17 years old</option>
              <option value="25">18-25 years old</option>
              <option value="30">26-30 years old</option>
              <option value="35">31-35 years old</option>
              <option value="40">36-40 years old</option>
              <option value="45">41-45 years old</option>
              <option value="50">46-50 years old</option>
              <option value="55">51-55 years old</option>
              <option value="60">56-60 years old</option>
              <option value="64">61-64 years old</option>
            </select>
          </div>
        </div>

        {stateData.familySize && stateData.maxAge ? (
          <PlanCards familySize={stateData.familySize} maxAge={stateData.maxAge} selectedPlan={stateData.planName} onSelect={onPlanSelected} onView={onPlanView} />
        ) : (
          <NoPlansSelected />
        )}

        <div className="sm:col-span-2">
          <label htmlFor="current-premium" className="block">
            Your Current Premium
          </label>
          <div className="mt-1">
            <input {...register("current-premium", { required: true })} type="number" className={currentPremiumClasses} placeholder="$1200" onChange={onCurrentPremiumChanged} />
          </div>
        </div>
      </div>

      <div className="p-3 md:p-5 lg:p-10 grid sm:grid-cols-1 md:grid-cols-3 gap-1 bg-primary border-0 rounded-b-lg">
        <div>
          <div className="text-white">Monthly Savings</div>
          <h2 className="text-white text-2xl font-bold">{formatCurrency(monthlySavings)}</h2>
        </div>

        <div>
          <div className="text-white">Yearly Savings</div>
          <h2 className="text-white text-2xl font-bold">{formatCurrency(yearlySavings)}</h2>
        </div>

        <button type="submit" className={buttonClasses}>
          {form.submitText || "Submit"}
        </button>

        {Object.keys(errors).length > 0 && (
          <div className="pt-5 text-red-500 md:col-span-3 text-center">
            Select a plan to continue.
          </div>
        )}
      </div>

      {comparePlan && (
        <ModalConfirm onConfirm={onPlanHide}>
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
            <Icon icon="info" size={20} color="white" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-xl leading-6 pb-2 border-b" id="modal-title">
              {comparePlan.name}
            </h3>
            <div className="mt-3">
              {comparePlan.features.map((feature: any, featureIndex: number) => {
                return <div key={`feature-${featureIndex}`}>
                  <h5 className="text-lg">
                    {feature.title}
                  </h5>
                  <p className="mt-1">
                    {feature.description}
                  </p>
                </div>
              })}
            </div>
          </div>
        </ModalConfirm>
      )}
    </form>
  )
}

export default QuoteTool
